<div class="row">
    <div class="col">
        <!-- Error -->
        <app-error [marginBottom]="true" [marginTop]="false"></app-error>
    </div>
</div>

<app-table-list
    [tableName]="'target-children'"
    [displayTableName]="'TARGETS' | translate"
    [data]="targets$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    [showColumnsSelection]="false"
    [showReport]="false"
    [showPagination]="false"
    [showFilter]="false"
    [selectable]="false"
    [hoverable]="false"
>
</app-table-list>
