import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { TargetsService } from "../../targets.service";
import { AnyTarget, ZixiPullTarget } from "../../../channels/channel";

@Component({
    selector: "app-target-parent",
    templateUrl: "./target-parent.component.html"
})
export class TargetParentComponent implements OnChanges {
    @Input() target: ZixiPullTarget;

    anyTarget: AnyTarget = null;

    constructor(private ts: TargetsService) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.target) {
            if (changes.target.previousValue && changes.target.currentValue) {
                if (
                    changes.target.previousValue.id !== changes.target.currentValue.id ||
                    changes.target.previousValue.type !== changes.target.currentValue.type
                ) {
                    if (changes.target.currentValue.parentZixiPull)
                        this.anyTarget = this.ts.prepTarget(changes.target.currentValue.parentZixiPull);
                    else this.anyTarget = null;
                }
            }
        }

        if (changes.target.currentValue.parentZixiPull)
            this.anyTarget = this.ts.prepTarget(changes.target.currentValue.parentZixiPull);
    }

    async release() {
        await this.ts.updateTarget(this.target, { zixi_pull_parent_id: null });
    }
}
