<button
    (click)="release()"
    class="btn btn-sm btn-outline-primary mb-3"
    title="{{
        ('TO_CONVERT_CHILD_TARGET_OF_THE_DYNAMIC_TARGET' | translate) +
            ' \'' +
            anyTarget.target.name +
            '\' ' +
            ('TO_AN_INDEPENDENT_PULL_TARGET_CLICK_HERE' | translate)
    }}"
    *ngIf="anyTarget"
>
    {{ "CONVERT_TO_AN_INDEPENDENT_PULL_TARGET" | translate }}
</button>
