import { Component, OnInit, OnChanges, Input, SimpleChanges } from "@angular/core";
import { take } from "rxjs/operators";

import { AnyTarget, ZixiPullTarget, SrtTarget } from "../../../channels/channel";
import { Constants } from "../../../../constants/constants";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "app-target-config",
    templateUrl: "./target-config.component.html"
})
export class TargetConfigComponent implements OnInit, OnChanges {
    @Input() target: AnyTarget;

    pwVisible: boolean;
    keyVisible: boolean;
    applicationHost: string;
    constants = Constants;

    pullTarget: ZixiPullTarget;
    srtTarget: SrtTarget;

    constructor(private userService: UsersService, private cbs: ClipboardService) {}

    ngOnInit() {
        this.userService.user.pipe(take(1)).subscribe(u => (this.applicationHost = u.application_host));

        this.targetChanged();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.target) {
            this.targetChanged();

            if (changes.target.previousValue && changes.target.currentValue) {
                if (changes.target.previousValue.id !== changes.target.currentValue.id) {
                    this.pwVisible = false;
                    this.keyVisible = false;
                }
            }
        }
    }

    private targetChanged() {
        if (this.target.pull) {
            this.pullTarget = this.target.target as ZixiPullTarget;
            this.srtTarget = null;
        }
        if (this.target.srt) {
            this.srtTarget = this.target.target as SrtTarget;
            this.pullTarget = null;
        }
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }
}
