import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { AnyTarget, MediaLiveHttpTarget } from "../../../channels/channel";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { BehaviorSubject } from "rxjs";
import { KeyMap } from "src/app/models/shared";
import { TranscodingProfile } from "src/app/pages/transcoding-profiles/transcoding-profile";
import { TranscodingProfilesService } from "src/app/pages/transcoding-profiles/transcoding-profiles.service";

@Component({
    selector: "app-target-transcoding-profiles",
    templateUrl: "./target-transcoding-profiles.component.html"
})
export class TargetTranscodingProfilesComponent implements OnInit, OnChanges {
    @Input() target: AnyTarget;
    @Input() autoRows? = true;

    private transcodingProfilesBS$ = new BehaviorSubject<TranscodingProfile[]>([]);

    tableColumnsSchema: TableSchema<TranscodingProfile>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<TranscodingProfile>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name,
            textValue: row => row.name,
            valueToExport: row => row.name
        },
        {
            header: this.translate.instant("VIDEO"),
            columnDef: "video",
            width: 280,
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getVideoColumnTextByRow(row),
                row => this.transcodingProfilesService.getVideoColumnTextByRow(row),
                () => true
            ),
            sortBy: row => row._frontData.videoOrder,
            textValue: row => row._frontData.videoOrder,
            valueToExport: row =>
                this.translate.instant(row.do_video ? "TRANSCODE" : row.keep_video ? "ORIGINAL" : "REMOVE")
        },
        {
            header: this.translate.instant("VIDEO_CODEC"),
            columnDef: "video_codec",
            width: 100,
            visible: true,
            sticky: 3,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getVideoCodecColumnTextByRow(row),
                row => this.transcodingProfilesService.getVideoCodecColumnTextByRow(row),
                row => !!row.do_video
            ),
            sortBy: row => (row.do_video ? this.transcodingProfilesService.getVideoCodecColumnTextByRow(row) : "-"),
            textValue: row => (row.do_video ? this.transcodingProfilesService.getVideoCodecColumnTextByRow(row) : "-")
        },
        {
            header: this.translate.instant("AUDIO"),
            columnDef: "audio",
            width: 100,
            visible: true,
            sticky: 4,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getAudioColumnTextByRow(row),
                row => this.transcodingProfilesService.getAudioColumnTextByRow(row),
                () => true
            ),
            sortBy: row => row._frontData.audioOrder,
            textValue: row => row._frontData.audioOrder,
            valueToExport: row => this.transcodingProfilesService.getAudioColumnTextByRow(row)
        },
        {
            header: this.translate.instant("AUDIO_CODEC"),
            columnDef: "audio_codec",
            width: 240,
            visible: true,
            sticky: 5,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
                row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
                row => !!row.do_audio
            ),
            sortBy: row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
            textValue: row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
            valueToExport: row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row)
        }
    ];

    constructor(private translate: TranslateService, private transcodingProfilesService: TranscodingProfilesService) {}

    ngOnInit() {
        this.prepTableData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.target) {
            this.prepTableData();
        }
    }

    get transcodingProfiles$() {
        return this.transcodingProfilesBS$.asObservable();
    }

    private prepTableData() {
        if ((this.target.target as unknown as MediaLiveHttpTarget).transcodingProfiles) {
            this.transcodingProfilesBS$.next(
                (this.target.target as unknown as MediaLiveHttpTarget).transcodingProfiles.map(o =>
                    this.transcodingProfilesService.prepTranscodingProfile(o)
                )
            );
        }
    }
}
