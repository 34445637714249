import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { TargetsService } from "../targets.service";
import { TargetObjectType } from "../../channels/channel";

@Injectable({
    providedIn: "root"
})
export class TargetListResolverService implements Resolve<TargetObjectType[]> {
    constructor(private ts: TargetsService) {}

    resolve(): Observable<TargetObjectType[]> | Observable<never> {
        return new Observable((observe: Subscriber<TargetObjectType[]>) => {
            this.ts.getAllTargets().then(() => {
                observe.next();
                observe.complete();
            });
        });
    }
}
