<form>
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div zmid="title" class="title">
                    <h1>{{ "CREATE_NEW_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- Type -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <h4>
                        {{ "SELECT_TARGET_TYPE" | translate }}
                    </h4>
                    <div class="card mb-3">
                        <ul class="list-group list-group-flush type-list">
                            <li
                                class="list-group-item"
                                (click)="goto('http')"
                                *ngIf="subtype !== 'mediaconnect' && subtype !== 'delivery' && subtype !== 'medialive'"
                                [class.disabled]="httpTags.length === 0"
                            >
                                <h4>{{ "HTTP" | translate }}</h4>
                                <div *ngIf="httpTags.length > 0">
                                    <p class="card-text">
                                        {{ "HTTP_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "HTTP_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="httpTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('zixi')"
                                *ngIf="subtype !== 'adaptive' && subtype !== 'medialive'"
                                [class.disabled]="pushTags.length === 0 && pullTags.length === 0"
                            >
                                <h4>{{ "ZIXI" | translate }}</h4>
                                <div *ngIf="pushTags.length > 0 || pullTags.length > 0">
                                    <p class="card-text">
                                        {{ "ZIXI_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "ZIXI_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="pushTags.length === 0 && pullTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('rist')"
                                *ngIf="subtype !== 'adaptive' && subtype !== 'medialive'"
                                [class.disabled]="ristTags.length === 0"
                            >
                                <h4>{{ "RIST" | translate }}</h4>
                                <div *ngIf="ristTags.length > 0">
                                    <p class="card-text">
                                        {{ "RIST_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "RIST_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="ristTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li class="list-group-item" (click)="goto('udp_rtp')" *ngIf="subtype !== 'adaptive'" [class.disabled]="udprtpTags.length === 0">
                                <h4>{{ "UDP/RTP" | translate }}</h4>
                                <div *ngIf="udprtpTags.length > 0">
                                    <p class="card-text">
                                        {{ "UDP/RTP_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "UDP/RTP_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="udprtpTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('rtmp')"
                                *ngIf="subtype !== 'mediaconnect' && subtype !== 'adaptive'"
                                [class.disabled]="rtmpTags.length === 0"
                            >
                                <h4>{{ "RTMP" | translate }}</h4>
                                <div *ngIf="rtmpTags.length > 0">
                                    <p class="card-text">
                                        {{ "RTMP_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "RTMP_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="rtmpTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('srt')"
                                *ngIf="subtype !== 'adaptive' && subtype !== 'medialive'"
                                [class.disabled]="srtTags.length === 0"
                            >
                                <h4>{{ "SRT" | translate }}</h4>
                                <div *ngIf="srtTags.length > 0">
                                    <p class="card-text">
                                        {{ "SRT_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "SRT_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="srtTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('ndi')"
                                *ngIf="subtype !== 'mediaconnect' && subtype !== 'adaptive' && subtype !== 'medialive'"
                                [class.disabled]="ndiTags.length === 0"
                            >
                                <h4>{{ "NDI" | translate }}</h4>
                                <div *ngIf="ndiTags.length > 0">
                                    <p class="card-text">
                                        {{ "NDI_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "NDI_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="ndiTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('cdi')"
                                *ngIf="subtype !== 'adaptive' && subtype !== 'delivery' && subtype !== 'medialive'"
                                [class.disabled]="cdiTags.length === 0"
                            >
                                <h4>{{ "CDI" | translate }}</h4>
                                <div *ngIf="cdiTags.length > 0">
                                    <p class="card-text">
                                        {{ "CDI_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "CDI_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="cdiTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('jpegxs')"
                                *ngIf="subtype !== 'adaptive' && subtype !== 'delivery' && subtype !== 'medialive'"
                                [class.disabled]="jpegxsTags.length === 0"
                            >
                                <h4>{{ "JPEGXS" | translate }}</h4>
                                <div *ngIf="jpegxsTags.length > 0">
                                    <p class="card-text">
                                        {{ "JPEGXS_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "JPEGXS_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="jpegxsTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('medialive_http')"
                                *ngIf="subtype !== 'mediaconnect' && subtype !== 'adaptive' && subtype !== 'delivery'"
                                [class.disabled]="medialiveTags.length === 0"
                            >
                                <h4>{{ "MEDIALIVE_HTTP" | translate }}</h4>
                                <div *ngIf="medialiveTags.length > 0">
                                    <p class="card-text">
                                        {{ "HTTP_MEDIALIVE_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "HTTP_MEDIALIVE_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="medialiveTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                            <li
                                class="list-group-item"
                                (click)="goto('entitlement')"
                                *ngIf="subtype !== 'adaptive' && subtype !== 'delivery' && subtype !== 'medialive'"
                                [class.disabled]="entitlementTags.length === 0"
                            >
                                <h4>{{ "ENTITLEMENT" | translate }}</h4>
                                <div *ngIf="entitlementTags.length > 0">
                                    <p class="card-text">
                                        {{ "ENTITLEMENT_TYPE_DESCRIPTION" | translate }}
                                    </p>
                                    <p class="card-text">
                                        <strong>{{ "CHANNELS" | translate }}:</strong> {{ "ENTITLEMENT_TARGET_CHANNEL_TYPES" | translate }}
                                    </p>
                                </div>
                                <div *ngIf="entitlementTags.length === 0">
                                    <p class="card-text">
                                        {{ "NO_PERMISSIONS" | translate }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr class="mt-0" />
                    <div class="text-start">
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
