<spinner-animation *ngIf="loading"></spinner-animation>

<div *ngIf="!loading">
    <div *ngIf="!traceroutes">
        <p class="mb-0">{{ "TRACEROUTE_NOT_AVAILABLE" | translate }}</p>
    </div>

    <div *ngIf="traceroutes && !traceroutes.success">
        <ngb-alert [type]="'danger'" [dismissible]="false">
            <strong>{{ "ERROR" | translate }}</strong> - {{ traceroutes.error?.message || traceroutes.error }}
        </ngb-alert>
    </div>

    <div *ngIf="traceroutes && traceroutes.success">
        <div *ngFor="let t of traceroutes.result">
            <div *ngIf="t.success">
                <div *ngIf="t.trace.status === 2">
                    <div>
                        <a
                            href="https://stefansundin.github.io/traceroute-mapper/?trace={{ urlEncodedTraceroute(t.trace.trace) }}"
                            target="_blank"
                            class="btn btn-outline-primary mb-2"
                            ><fa-icon icon="route" size="md" class="me-1"></fa-icon>{{ "TRACEROUTE_MAP" | translate }}</a
                        >
                    </div>
                    <pre>{{ t.trace.trace }}</pre>
                </div>
                <div *ngIf="t.trace.status === 3">
                    <pre>{{ t.trace.error }}</pre>
                </div>
                <div *ngIf="t.trace && t.trace.status !== 3 && t.trace.status !== 2">
                    <pre>{{ "TRACE_IN_PROGRESS" | translate }}...</pre>
                </div>
            </div>
            <div *ngIf="!t.success">
                <ngb-alert [type]="'danger'" [dismissible]="false">
                    <strong>{{ "ERROR" | translate }}</strong> - {{ "BROADCASTER" | translate }} {{ t.broadcaster }}, {{ "ERROR" | translate }}: {{ t.error }}
                </ngb-alert>
            </div>
        </div>
    </div>
</div>
