<!-- Details Panel -->
<div class="details-panel" *ngIf="target">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ target.target.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon>
                </button>
                <zx-status-full
                    *ngIf="target.adaptive"
                    [model]="target.target"
                    [text]="target.status"
                    [channelDisabled]="target.target.adaptive_channel_id != null && !target.target.adaptiveChannel?.is_enabled"
                    [showStatusText]="false"
                    [showOtherIcons]="false"
                ></zx-status-full
                ><zx-status-full
                    *ngIf="target.delivery"
                    [model]="target.target"
                    [text]="target.status"
                    [channelDisabled]="
                        (target.target.delivery_channel_id != null && !target.target.deliveryChannel?.is_enabled) ||
                        (target.target.mediaconnect_flow_id != null && !target.target.mediaconnectFlow?.is_enabled)
                    "
                    [showStatusText]="false"
                    [showOtherIcons]="false"
                ></zx-status-full
                ><zx-status-full
                    *ngIf="target.mediaconnect && !target.delivery"
                    [model]="target.target"
                    [text]="target.status"
                    [channelDisabled]="target.target.mediaconnect_flow_id != null && !target.target.mediaconnectFlow?.is_enabled"
                    [showStatusText]="false"
                    [showOtherIcons]="false"
                ></zx-status-full
                ><zx-status-full
                    *ngIf="target.medialive && !target.delivery"
                    [model]="target.target"
                    [text]="target.status"
                    [channelDisabled]="target.target.medialive_channel_id != null && !target.target.mediaLiveChannel?.is_enabled"
                    [showStatusText]="false"
                    [showOtherIcons]="false"
                ></zx-status-full
                >{{ target.target.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- View Radio Button -->
            <div class="d-block float-end">
                <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                <mat-button-toggle-group id="viewOptions" name="viewOption" aria-label="view option" [(ngModel)]="viewOption" (change)="viewChange()">
                    <mat-button-toggle value="accordions" title="{{ 'ACCORDION_LIST' | translate }}">
                        <fa-icon icon="th-list" size="sm" flip="horizontal"></fa-icon>
                        <span class="sr-only">{{ "PANELS" | translate }}</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="tabs" title="{{ 'TABS' | translate }}">
                        <fa-icon icon="folder" size="sm"></fa-icon>
                        <span class="sr-only">{{ "TABS" | translate }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary me-2 mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <zx-mute [model]="target.target" [type]="'zixi_pull'" class="w-100" *ngIf="canEdit(target) && target.pull"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'zixi_push'" class="w-100" *ngIf="canEdit(target) && target.push"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'rtmp_push'" class="w-100" *ngIf="canEdit(target) && target.rtmp"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'publishingTarget'" class="w-100" *ngIf="canEdit(target) && target.adaptive"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'udp_rtp'" class="w-100" *ngIf="canEdit(target) && target.udp_rtp"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'rist'" class="w-100" *ngIf="canEdit(target) && target.rist"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'srt'" class="w-100" *ngIf="canEdit(target) && target.srt"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'ndi'" class="w-100" *ngIf="canEdit(target) && target.ndi"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'cdi'" class="w-100" *ngIf="canEdit(target) && target.cdi"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'jpegxs'" class="w-100" *ngIf="canEdit(target) && target.jpegxs"></zx-mute>
                            <zx-mute [model]="target.target" [type]="'entitlement'" class="w-100" *ngIf="canEdit(target) && target.entitlement"></zx-mute>
                            <button
                                class="text-secondary"
                                (click)="switchChannel()"
                                title="{{ 'SWITCH_CHANNEL' | translate }}"
                                mat-menu-item
                                *ngIf="canEdit(target) && target.target.zixi_pull_parent_id == null && !target.cdi && !target.jpegxs && !target.entitlement"
                            >
                                <fa-icon icon="random" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "SWITCH_CHANNEL" | translate }}
                            </button>
                            <button
                                (click)="editTarget(target.target.name)"
                                class="text-primary"
                                title="{{ 'EDIT' | translate }}"
                                mat-menu-item
                                *ngIf="canEdit(target) && target.target.zixi_pull_parent_id == null"
                            >
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                (click)="cloneTarget(target.target.name)"
                                class="text-primary"
                                title="{{ 'CLONE' | translate }}"
                                [disabled]="
                                    !resourceTags?.length ||
                                    (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                                "
                                mat-menu-item
                                *ngIf="target.target.zixi_pull_parent_id == null && target.type !== 'mediastore' && target.type !== 's3'"
                            >
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button
                                (click)="toggle()"
                                [ngClass]="target.target.is_enabled ? 'text-warning' : 'text-success'"
                                title="{{ (target.target.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                                mat-menu-item
                                *ngIf="canEdit(target)"
                            >
                                <fa-icon icon="power-off" size="sm" [fixedWidth]="true" *ngIf="!target.target.is_enabled"></fa-icon>
                                <fa-icon icon="ban" size="sm" [fixedWidth]="true" *ngIf="target.target.is_enabled"></fa-icon>
                                {{ (target.target.is_enabled ? "DISABLE" : "ENABLE") | translate }}
                            </button>
                            <button (click)="deleteTarget()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item *ngIf="canEdit(target)">
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <button
                    type="button"
                    class="btn btn-outline-secondary me-2 mb-2"
                    (click)="switchChannel()"
                    title="{{ 'SWITCH_CHANNEL' | translate }}"
                    *ngIf="canEdit(target) && target.target.zixi_pull_parent_id == null && !target.cdi && !target.jpegxs && !target.entitlement"
                >
                    <fa-icon icon="random" size="sm"></fa-icon>
                    <span class="d-none d-xxl-inline-block ms-1">{{ "CHANNEL" | translate }}</span>
                </button>
                <zx-mute [model]="target.target" [type]="'zixi_pull'" class="me-2 mb-2" *ngIf="canEdit(target) && target.pull"></zx-mute>
                <zx-mute [model]="target.target" [type]="'zixi_push'" class="me-2 mb-2" *ngIf="canEdit(target) && target.push"></zx-mute>
                <zx-mute [model]="target.target" [type]="'rtmp_push'" class="me-2 mb-2" *ngIf="canEdit(target) && target.rtmp"></zx-mute>
                <zx-mute [model]="target.target" [type]="'publishingTarget'" class="me-2 mb-2" *ngIf="canEdit(target) && target.adaptive"></zx-mute>
                <zx-mute [model]="target.target" [type]="'udp_rtp'" class="me-2 mb-2" *ngIf="canEdit(target) && target.udp_rtp"></zx-mute>
                <zx-mute [model]="target.target" [type]="'rist'" class="me-2 mb-2" *ngIf="canEdit(target) && target.rist"></zx-mute>
                <zx-mute [model]="target.target" [type]="'srt'" class="me-2 mb-2" *ngIf="canEdit(target) && target.srt"></zx-mute>
                <zx-mute [model]="target.target" [type]="'ndi'" class="me-2 mb-2" *ngIf="canEdit(target) && target.ndi"></zx-mute>
                <zx-mute [model]="target.target" [type]="'cdi'" class="me-2 mb-2" *ngIf="canEdit(target) && target.cdi"></zx-mute>
                <zx-mute [model]="target.target" [type]="'jpegxs'" class="me-2 mb-2" *ngIf="canEdit(target) && target.jpegxs"></zx-mute>
                <zx-mute [model]="target.target" [type]="'entitlement'" class="me-2 mb-2" *ngIf="canEdit(target) && target.entitlement"></zx-mute>
                <div class="btn-group me-2 mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="goToDR()"
                        title="{{ 'GO_TO' | translate }} {{
                            recoveryState === RecoveryState.alternative ? ('PRIMARY' | translate) : ('ALTERNATIVE' | translate)
                        }}"
                        *ngIf="canEdit(target) && recoveryState !== RecoveryState.none"
                    >
                        <fa-icon icon="alt" size="sm" [fixedWidth]="true"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1"
                            >{{ "GO_TO" | translate }}
                            {{ recoveryState === RecoveryState.alternative ? ("PRIMARY" | translate) : ("ALTERNATIVE" | translate) }}</span
                        >
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editTarget(target.target.name)"
                        title="{{ 'EDIT' | translate }}"
                        *ngIf="canEdit(target) && target.target.zixi_pull_parent_id == null"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="cloneTarget(target.target.name)"
                        title="{{ 'CLONE' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                        *ngIf="target.target.zixi_pull_parent_id == null"
                    >
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="target.target.is_enabled ? 'btn-outline-warning' : 'btn-outline-success'"
                        (click)="toggle()"
                        title="{{ (target.target.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                        *ngIf="canEdit(target)"
                    >
                        <fa-icon icon="power-off" size="sm" *ngIf="!target.target.is_enabled"></fa-icon>
                        <fa-icon icon="ban" size="sm" *ngIf="target.target.is_enabled"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ (target.target.is_enabled ? "DISABLE" : "ENABLE") | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="deleteTarget()" title="{{ 'DELETE' | translate }}" *ngIf="canEdit(target)">
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions" *ngIf="view === 'accordions'">
        <!-- Alerts -->
        <div class="row" *ngIf="!target.dynamic">
            <div class="col">
                <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="target.target.active_flapping && target.target.is_enabled">
                    <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }} {{ target.target.flapping | date : "MMM d, y, h:mm:ss a" }}
                </ngb-alert>
                <zx-active-states
                    [activeStates]="target.target.activeStates"
                    [refreshFunction]="refreshTarget"
                    [canEdit]="canEdit(target)"
                    [objectName]="target.target.name"
                ></zx-active-states>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xxxl-8">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-xxxl-none">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <!-- Auto-pull Target -->
                            <div class="row" *ngIf="target.target.zixi_pull_parent_id != null">
                                <div class="col">
                                    <app-target-parent [target]="target.target"></app-target-parent>
                                </div>
                            </div>
                            <app-target-details [(target)]="target"></app-target-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Preview -->
                <ng-container *ngIf="targetSource$ | async as source">
                    <ng-container *ngIf="!isTargetDown(target)">
                        <ngb-accordion
                            #previewMobileAccordion="ngbAccordion"
                            activeIds="ngb-panel-preview-mobile"
                            class="d-xxxl-none padding-5px panel-overflow-visible"
                            *ngIf="isMobile$ | async"
                        >
                            <ngb-panel id="ngb-panel-preview-mobile">
                                <ng-template ngbPanelTitle>
                                    <h3 translate title="{{ 'PREVIEW' | translate }}">PREVIEW</h3>
                                    <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                    <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="form-group mb-1 ms-2">
                                        <app-checkbox-field
                                            id="showOverlay"
                                            class="mb-0"
                                            [label]="'SHOW_OVERLAY' | translate"
                                            [formControl]="showOverlayControl"
                                        ></app-checkbox-field>
                                    </div>
                                    <app-source-thumbnail [source]="source" [info]="false" [overlay]="showOverlayControl.value"></app-source-thumbnail>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </ng-container>
                </ng-container>

                <!-- Auto-pull child targets -->
                <ngb-accordion #childTargetsAccordion="ngbAccordion" activeIds="ngb-panel-child-targets" class="no-padding" *ngIf="target.dynamic">
                    <ngb-panel id="ngb-panel-child-targets">
                        <ng-template ngbPanelTitle>
                            <h3 title="{{ 'CHILD' | translate }} {{ 'TARGETS' | translate }}">{{ "CHILD" | translate }} {{ "TARGETS" | translate }}</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent><app-target-children [targets]="target.target.childZixiPulls"></app-target-children></ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Diagram -->
                <ngb-accordion #diagramAccordion="ngbAccordion" activeIds="ngb-panel-diagram" class="no-padding">
                    <ngb-panel id="ngb-panel-diagram">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DIAGRAM' | translate }}">DIAGRAM</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-react-flow-diagram [(model)]="target.target" type="target" subtype="{{ target.apiType }}"></zx-react-flow-diagram>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- History -->
                <ngb-accordion
                    #historyAccordion="ngbAccordion"
                    activeIds="ngb-panel-history"
                    *ngIf="
                        target.target.mediaconnect_flow_id == null &&
                        target.target.medialive_channel_id == null &&
                        !target.dynamic &&
                        target.type !== 'cdi' &&
                        target.type !== 'jpegxs' &&
                        !target.medialive_http
                    "
                >
                    <ngb-panel id="ngb-panel-history">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'HISTORY' | translate }}">HISTORY</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-history-graph
                                [title]=""
                                [object]="{ zixi_pull: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.pull"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ zixi_push: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.push"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ rtmp_push: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.rtmp"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ publishing_target: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.adaptive"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ udp_rtp: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.udp_rtp"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ rist: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.rist"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ srt_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.srt"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ ndi_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.ndi"
                            ></zx-history-graph>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Events -->
                <ngb-accordion #eventsAccordion="ngbAccordion" activeIds="ngb-panel-events" class="no-padding">
                    <ngb-panel id="ngb-panel-events">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'EVENTS' | translate }}">EVENTS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <!-- 11 Types -->
                            <zx-events
                                [objects]="{ zixi_pull: pullTargetWithChildren() }"
                                [id]="target.target.id"
                                [autoRows]="false"
                                *ngIf="target.pull"
                            ></zx-events>
                            <zx-events [objects]="{ zixi_push: target.target }" [id]="target.target.id" [autoRows]="false" *ngIf="target.push"></zx-events>
                            <zx-events [objects]="{ rtmp_push: target.target }" [id]="target.target.id" [autoRows]="false" *ngIf="target.rtmp"></zx-events>
                            <zx-events
                                [objects]="{ publishing_target: target.target }"
                                [id]="target.target.id"
                                [autoRows]="false"
                                *ngIf="target.adaptive"
                            ></zx-events>
                            <zx-events [objects]="{ udp_rtp: target.target }" [id]="target.target.id" [autoRows]="false" *ngIf="target.udp_rtp"></zx-events>
                            <zx-events [objects]="{ rist: target.target }" [id]="target.target.id" [autoRows]="false" *ngIf="target.rist"></zx-events>
                            <zx-events [objects]="{ srt_targets: target.target }" [id]="target.target.id" [autoRows]="false" *ngIf="target.srt"></zx-events>
                            <zx-events [objects]="{ ndi_targets: target.target }" [id]="target.target.id" [autoRows]="false" *ngIf="target.ndi"></zx-events>
                            <zx-events
                                [objects]="{ mediaconnect_cdi_targets: target.target }"
                                [id]="target.target.id"
                                [autoRows]="false"
                                *ngIf="target.cdi"
                            ></zx-events>
                            <zx-events
                                [objects]="{ mediaconnect_jpegxs_targets: target.target }"
                                [id]="target.target.id"
                                [autoRows]="false"
                                *ngIf="target.jpegxs"
                            ></zx-events>
                            <zx-events
                                [objects]="{ medialive_http_targets: target.target }"
                                [id]="target.target.id"
                                [autoRows]="false"
                                *ngIf="target.medialive_http"
                            ></zx-events>
                            <zx-events
                                [objects]="{ mediaconnect_entitlement_targets: target.target }"
                                [id]="target.target.id"
                                [autoRows]="false"
                                *ngIf="target.entitlement"
                            ></zx-events>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Transcoding Profiles -->
                <ngb-accordion
                    *ngIf="target.target?.transcodingProfiles?.length"
                    class="no-padding"
                    #transcodingAccordion="ngbAccordion"
                    activeIds="ngb-panel-transcoding"
                >
                    <ngb-panel id="ngb-panel-transcoding">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'TRANSCODING_PROFILES' | translate }}">{{ "TRANSCODING_PROFILES" | translate }}</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-target-transcoding-profiles [target]="target" [autoRows]="false"></app-target-transcoding-profiles>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Change Tracking -->
                <ngb-accordion *ngIf="canEdit(target)" class="no-padding" #trackingChangesAccordion="ngbAccordion" activeIds="ngb-panel-tracking-changes">
                    <ngb-panel id="ngb-panel-tracking-changes">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'CHANGE_TRACKING' | translate }}">{{ "CHANGE_TRACKING" | translate }}</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-object-tracking-main-list
                                #objectTrackingList
                                [objectId]="target.target.id"
                                [objectName]="target?.target?.name"
                                [objectType]="targetBackendType()"
                                [objectApiType]="objectTrackingApiType()"
                                [refreshObjectDataFunction]="refreshTargetPromise.bind(this)"
                                [autoRows]="false"
                            ></zx-object-tracking-main-list>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Incidents -->
                <ngb-accordion #incidentsAccordion="ngbAccordion" activeIds="ngb-panel-incidents" *ngIf="targetBackendType()" class="no-padding">
                    <ngb-panel id="ngb-panel-incidents">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'INCIDENTS' | translate }}">{{ "INCIDENTS" | translate }}</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-object-incident-list [objectType]="targetBackendType()" [objectId]="target.id" [bordered]="true"></app-object-incident-list>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Traceroute -->
                <ngb-accordion
                    #tracerouteAccordion="ngbAccordion"
                    class="no-padding-btm"
                    *ngIf="target.target.is_enabled && target.target.status && target.target.status?.trace && !target.dynamic"
                >
                    <ngb-panel id="ngb-panel-tr">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'TRACEROUTE' | translate }}">TRACEROUTE</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-target-traceroute [(target)]="target"></app-target-traceroute>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

            <div class="col-12 col-xxxl-4">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-none d-xxxl-block">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <!-- Auto-pull Target -->
                            <div class="row" *ngIf="target.target.zixi_pull_parent_id != null">
                                <div class="col">
                                    <app-target-parent [target]="target.target"></app-target-parent>
                                </div>
                            </div>
                            <app-target-details [(target)]="target"></app-target-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Preview -->
                <ng-container *ngIf="targetSource$ | async as source">
                    <ng-container *ngIf="!isTargetDown(target)">
                        <ngb-accordion
                            #previewAccordion="ngbAccordion"
                            activeIds="ngb-panel-preview"
                            class="d-none d-xxxl-block padding-5px panel-overflow-visible"
                            *ngIf="isHuge$ | async"
                        >
                            <ngb-panel id="ngb-panel-preview">
                                <ng-template ngbPanelTitle>
                                    <h3 translate title="{{ 'PREVIEW' | translate }}">PREVIEW</h3>
                                    <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                    <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="form-group mb-1 ms-2">
                                        <app-checkbox-field
                                            id="showOverlay"
                                            class="mb-0"
                                            [label]="'SHOW_OVERLAY' | translate"
                                            [formControl]="showOverlayControl"
                                        ></app-checkbox-field>
                                    </div>
                                    <app-source-thumbnail [source]="source" [info]="false" [overlay]="showOverlayControl.value"></app-source-thumbnail>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </ng-container>
                </ng-container>

                <!-- Notes -->
                <ngb-accordion #notesAccordion="ngbAccordion" activeIds="ngb-panel-notes" *ngIf="target">
                    <ngb-panel id="ngb-panel-notes">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'NOTES' | translate }}">NOTES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'zixi_pull'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.pull"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'zixi_push'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.push"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'rtmp_push'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.rtmp"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'publishingTarget'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.adaptive"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'udp_rtp'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.udp_rtp"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'rist'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.rist"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'srt'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.srt"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'ndi'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.ndi"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'cdi'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.cdi"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'jpegxs'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.jpegxs"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'medialive_http'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.medialive_http"
                            ></zx-advanced-notes>
                            <zx-advanced-notes
                                [model]="target.target"
                                [type]="'entitlement'"
                                [id]="target.target.id"
                                [canEdit]="canEdit(target)"
                                *ngIf="target.entitlement"
                            ></zx-advanced-notes>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Config -->
                <ngb-accordion #configAccordion="ngbAccordion" activeIds="ngb-panel-config" *ngIf="target.pull || (target.srt && target.target.pull_mode)">
                    <ngb-panel id="ngb-panel-config">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'CONFIGURATION' | translate }}">CONFIGURATION</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-target-config [(target)]="target"></app-target-config>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>

    <!-- Tab Layout -->
    <div class="details-content-tabs" *ngIf="view === 'tabs'">
        <button type="button" class="btn btn-link scroll-left-btn" (click)="scrollLeft()" title="{{ 'SCROLL_LEFT' | translate }}" *ngIf="tabsetHasScroll">
            <fa-icon icon="chevron-left" size="md"></fa-icon>
        </button>
        <button type="button" class="btn btn-link scroll-right-btn" (click)="scrollRight()" title="{{ 'SCROLL_RIGHT' | translate }}" *ngIf="tabsetHasScroll">
            <fa-icon icon="chevron-right" size="md"></fa-icon>
        </button>
        <!-- Tabs -->
        <div class="tabset">
            <ul
                ngbNav
                [destroyOnHide]="false"
                [(activeId)]="activeTab"
                #targetTabset="ngbNav"
                [ngClass]="{ hasScroll: tabsetHasScroll }"
                class="justify-content-start nav nav-tabs"
            >
                <li ngbNavItem="details-tab" id="details-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="align-justify" size="sm" title="{{ 'DETAILS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "DETAILS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <!-- Alerts -->
                            <div class="row">
                                <div class="col">
                                    <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="target.target.active_flapping && target.target.is_enabled">
                                        <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
                                        {{ target.target.flapping | date : "MMM d, y, h:mm:ss a" }}
                                    </ngb-alert>
                                    <zx-active-states
                                        [activeStates]="target.target.activeStates"
                                        [refreshFunction]="refreshTarget"
                                        [canEdit]="canEdit(target)"
                                        [objectName]="target.target.name"
                                    ></zx-active-states>
                                </div>
                            </div>
                            <!-- Auto-pull Target -->
                            <div class="row" *ngIf="target.target.zixi_pull_parent_id != null">
                                <div class="col">
                                    <app-target-parent [target]="target.target"></app-target-parent>
                                </div>
                            </div>
                            <!-- Details -->
                            <div class="row">
                                <div class="col-12 col-xxl-6 mb-3">
                                    <h3 class="d-md-none" title="{{ 'DETAILS' | translate }}">{{ "DETAILS" | translate }}</h3>
                                    <app-target-details [(target)]="target"></app-target-details>
                                </div>
                                <!-- Thumbnail -->
                                <ng-container *ngIf="targetSource$ | async as source">
                                    <div class="col-12 col-xxl-6 mb-3" *ngIf="!isTargetDown(target)">
                                        <app-checkbox-field
                                            id="showOverlay"
                                            class="mb-0"
                                            [label]="'SHOW_OVERLAY' | translate"
                                            [formControl]="showOverlayControl"
                                        ></app-checkbox-field>
                                        <app-source-thumbnail [source]="source" [info]="false" [overlay]="showOverlayControl.value"></app-source-thumbnail>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="child-targets-tab" id="child-targets-tab" *ngIf="target.dynamic">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="server" size="sm" title="{{ 'CHILD' | translate }} {{ 'TARGETS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "CHILD" | translate }} {{ "TARGETS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'CHILD' | translate }} {{ 'TARGETS' | translate }}">
                                {{ "CHILD" | translate }} {{ "TARGETS" | translate }}
                            </h3>
                            <app-target-children [targets]="target.target.childZixiPulls"></app-target-children>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="diagram-tab" id="diagram-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="sitemap" size="xs" title="{{ 'DIAGRAM' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "DIAGRAM" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent id="diagram-tab-panel">
                        <h3 class="d-md-none ms-3 mt-3" title="{{ 'DIAGRAM' | translate }}">{{ "DIAGRAM" | translate }}</h3>
                        <zx-react-flow-diagram
                            [(model)]="target.target"
                            type="target"
                            subtype="{{ target.apiType }}"
                            *ngIf="activeTab === 'diagram-tab'"
                        ></zx-react-flow-diagram>
                    </ng-template>
                </li>
                <li
                    ngbNavItem="history-tab"
                    id="history-tab"
                    *ngIf="
                        target.target.mediaconnect_flow_id == null &&
                        target.target.medialive_channel_id == null &&
                        !target.dynamic &&
                        target.type !== 'cdi' &&
                        target.type !== 'jpegxs' &&
                        !target.medialive_http
                    "
                >
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="chart-line" size="md" title="{{ 'HISTORY' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "HISTORY" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'HISTORY' | translate }}">{{ "HISTORY" | translate }}</h3>
                            <zx-history-graph
                                [title]=""
                                [object]="{ zixi_pull: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.pull && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ zixi_push: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.push && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ rtmp_push: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.rtmp && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ publishing_target: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.adaptive && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ udp_rtp: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.udp_rtp && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ rist: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.rist && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ srt_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.srt && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ ndi_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.ndi && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ mediaconnect_cdi_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.cdi && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ mediaconnect_jpegxs_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.jpegxs && activeTab === 'history-tab'"
                            ></zx-history-graph>
                            <zx-history-graph
                                [title]=""
                                [object]="{ medialive_http_targets: target.target }"
                                [graphUrl]="targetDashboardLink"
                                *ngIf="target.medialive_http && activeTab === 'history-tab'"
                            ></zx-history-graph>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="nd-tab" id="nd-tab" *ngIf="target.target.health?.healthScore != null">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="heartbeat" size="md" title="{{ 'HEALTH' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "HEALTH" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'HEALTH' | translate }}">{{ "HEALTH" | translate }}</h3>
                            <div *ngIf="activeTab === 'nd-tab'">
                                <app-target-nd [(target)]="target" [from]="historyFrom" [to]="historyTo"></app-target-nd>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="events-tab" id="events-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="exclamation-triangle" size="sm" title="{{ 'EVENTS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "EVENTS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'EVENTS' | translate }}">{{ "EVENTS" | translate }}</h3>
                            <zx-events
                                [objects]="{ zixi_pull: pullTargetWithChildren() }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.pull"
                            ></zx-events>
                            <zx-events
                                [objects]="{ zixi_push: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.push"
                            ></zx-events>
                            <zx-events
                                [objects]="{ rtmp_push: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.rtmp"
                            ></zx-events>
                            <zx-events
                                [objects]="{ publishing_target: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.adaptive"
                            ></zx-events>
                            <zx-events
                                [objects]="{ udp_rtp: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.udp_rtp"
                            ></zx-events>
                            <zx-events
                                [objects]="{ rist: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.rist"
                            ></zx-events>
                            <zx-events
                                [objects]="{ srt_targets: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.srt"
                            ></zx-events>
                            <zx-events
                                [objects]="{ ndi_targets: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.ndi"
                            ></zx-events>
                            <zx-events
                                [objects]="{ mediaconnect_cdi_targets: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.cdi"
                            ></zx-events>
                            <zx-events
                                [objects]="{ mediaconnect_jpegxs_targets: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.jpegxs"
                            ></zx-events>
                            <zx-events
                                [objects]="{ medialive_http_targets: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.medialive_http"
                            ></zx-events>
                            <zx-events
                                [objects]="{ mediaconnect_entitlement_targets: target.target }"
                                [id]="target.target.id"
                                bordered="true"
                                *ngIf="activeTab === 'events-tab' && target.entitlement"
                            ></zx-events>
                        </div>
                    </ng-template>
                </li>

                <!-- Transcoding Profiles -->
                <li ngbNavItem="transcoding-profiles-tab" id="transcoding-profile-tab" *ngIf="target.target?.transcodingProfiles?.length">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="magic" size="md" title="{{ 'TRANSCODING_PROFILES' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "TRANSCODING_PROFILES" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'TRANSCODING_PROFILES' | translate }}">{{ "TRANSCODING_PROFILES" | translate }}</h3>
                            <app-target-transcoding-profiles [target]="target"></app-target-transcoding-profiles>
                        </div>
                    </ng-template>
                </li>

                <li *ngIf="canEdit(target)" ngbNavItem="tracking-changes-tab" id="tracking-changes-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="clock-rotate-left" size="md" title="{{ 'CHANGE_TRACKING' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "CHANGE_TRACKING" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'CHANGE_TRACKING' | translate }}">{{ "CHANGE_TRACKING" | translate }}</h3>
                            <zx-object-tracking-main-list
                                #objectTrackingList
                                [objectId]="target.target.id"
                                [objectName]="target?.target?.name"
                                [objectType]="targetBackendType()"
                                [objectApiType]="objectTrackingApiType()"
                                [refreshObjectDataFunction]="refreshTargetPromise.bind(this)"
                            ></zx-object-tracking-main-list>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="help-tab" id="help-tab" *ngIf="target.pull || (target.srt && target.target.pull_mode)">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="question-circle" size="md" title="{{ 'CONFIGURATION' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "CONFIGURATION" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'CONFIGURATION' | translate }}">{{ "CONFIGURATION" | translate }}</h3>
                            <app-target-config [(target)]="target"></app-target-config>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="notes-tab" id="notes-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon [icon]="['far', 'sticky-note']" size="md" title="{{ 'NOTES' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "NOTES" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'NOTES' | translate }}">{{ "NOTES" | translate }}</h3>
                            <form>
                                <div class="form-group">
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'zixi_pull'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.pull"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'zixi_push'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.push"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'rtmp_push'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.rtmp"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'publishingTarget'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.adaptive"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'udp_rtp'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.udp_rtp"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'rist'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.rist"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'srt'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.srt"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'ndi'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.ndi"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'cdi'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.cdi"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'jpegxs'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.jpegxs"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'medialive_http'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.medialive_http"
                                    ></zx-advanced-notes>
                                    <zx-advanced-notes
                                        [model]="target.target"
                                        [type]="'entitlement'"
                                        [id]="target.target.id"
                                        [canEdit]="canEdit(target)"
                                        *ngIf="target.entitlement"
                                    ></zx-advanced-notes>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="incidents-tab" id="incidents-tab" *ngIf="targetBackendType()">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="file-exclamation" size="sm" title="{{ 'INCIDENTS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "INCIDENTS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'INCIDENTS' | translate }}">{{ "INCIDENTS" | translate }}</h3>
                            <app-object-incident-list
                                [objectType]="targetBackendType()"
                                [objectId]="target.id"
                                [autoRows]="true"
                                *ngIf="activeTab === 'incidents-tab'"
                            ></app-object-incident-list>
                        </div>
                    </ng-template>
                </li>
                <li
                    ngbNavItem="traceroute-tab"
                    id="traceroute-tab"
                    *ngIf="target.target.is_enabled && target.target.status && target.target.status?.trace && !target.dynamic"
                >
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="list-ol" size="md" title="{{ 'TRACEROUTE' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "TRACEROUTE" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'TRACEROUTE' | translate }}">{{ "TRACEROUTE" | translate }}</h3>
                            <app-target-traceroute [(target)]="target" *ngIf="activeTab === 'traceroute-tab'"></app-target-traceroute>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="targetTabset" [ngClass]="{ 'diagram-tab-panel': activeTab === 'diagram-tab' }"></div>
        </div>
    </div>
</div>
