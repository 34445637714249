import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { TargetsService } from "../../targets.service";
import { TargetsTraceRoutes } from "../../../../models/shared";
import { AnyTarget } from "../../../channels/channel";

@Component({
    selector: "app-target-traceroute",
    templateUrl: "./target-traceroute.component.html"
})
export class TargetTracerouteComponent implements OnInit, OnChanges, OnDestroy {
    @Input() target: AnyTarget;
    loading: boolean;
    traceroutes: TargetsTraceRoutes;

    private tracerouteSubscription: Subscription;

    constructor(private ts: TargetsService) {}

    ngOnInit() {
        this.loading = true;
        this.ts.refreshTraceroutes(this.target.target);

        this.tracerouteSubscription = this.ts.traceroutes.subscribe(traceroutes => {
            this.traceroutes = traceroutes;

            const oks = traceroutes.result.filter(r => r.success);
            if (oks.length > 0) this.traceroutes.result = [oks[0]];

            if (this.traceroutes) {
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.tracerouteSubscription.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.target) {
            if (changes.target.previousValue && changes.target.currentValue) {
                if (changes.target.previousValue.id !== changes.target.currentValue.id) {
                    this.loading = true;
                    await this.ts.refreshTraceroutes(changes.target.currentValue.target).toPromise();
                    this.loading = false;
                }
            }
        }
    }

    async refresh() {
        this.loading = true;
        await this.ts.refreshTraceroutes(this.target.target).toPromise();
        this.loading = false;
    }

    urlEncodedTraceroute(s: string) {
        return encodeURIComponent(s.replace(/^[^\n]+\n/, ""));
    }
}
