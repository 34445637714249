import { ComponentRef } from "@angular/core";
import { KeyMap, SomeZixiObject } from "../../../models/shared";
import { AnyTarget } from "../../../pages//channels/channel";
import { ZxBroadcasterComponent } from "./zx-broadcaster.component";

export function assignComponentsBroadcastAdapter(
    broadcasterComponentRef: ComponentRef<ZxBroadcasterComponent>,
    row: KeyMap<AnyTarget>,
    searchTerm: string[]
) {
    const broadcasterComponentInstance = broadcasterComponentRef.instance;
    broadcasterComponentInstance.model = row._frontData?.active_broadcaster as unknown as SomeZixiObject;
    broadcasterComponentInstance.showTag = false;
    broadcasterComponentInstance.showLink = true;
    broadcasterComponentInstance.showCluster = false;
    broadcasterComponentInstance.showStatusIcon = false;
    broadcasterComponentInstance.clusterId =
        row.target.deliveryChannel?.broadcaster_cluster_id ?? row.target.adaptiveChannel?.broadcaster_cluster_id;
    broadcasterComponentInstance.searchTerm = searchTerm;
}
