<div class="details-form" *ngIf="pullTarget">
    <h4 class="mb-3" translate>CONFIGURE_ZIXI_ENABLED_DECODER_OR_RECEIVER_WITH_FOLLOWING_PARAMS</h4>
    <div class="form-group row">
        <h5 class="col-12" translate>HOST</h5>
        <div class="col-12">
            <span
                >{{ applicationHost
                }}<button class="btn btn-sm btn-xs btn-copy" (click)="copyString(applicationHost)" type="button" title="{{ 'COPY' | translate }}">
                    <fa-icon icon="clipboard" size="md"></fa-icon></button
            ></span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>PORT</h5>
        <div class="col-12">
            <span>{{ constants.zixiPorts.regular.public }} {{ "OR" | translate }} {{ constants.zixiPorts.dtls.public }} (DTLS)</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>STREAM_ID</h5>
        <div class="col-12">
            <span *ngIf="!pullTarget.merge_mode"
                >{{ pullTarget.stream_id }}
                <button class="btn btn-sm btn-xs btn-copy" (click)="copyString(pullTarget.stream_id)" type="button" title="{{ 'COPY' | translate }}">
                    <fa-icon icon="clipboard" size="md"></fa-icon>
                </button>
            </span>
            <span *ngIf="!!pullTarget.merge_mode">{{ pullTarget.stream_id }}-1 and {{ pullTarget.stream_id }}-2</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="pullTarget.password">
        <h5 class="col-12" translate>PASSWORD</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                {{ "SHOW" | translate }}
            </a>
            <span *ngIf="pwVisible">{{ pullTarget.password }}</span> /
            <a href="javascript:void(0)" (click)="copyString(pullTarget.password)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
    <div class="form-group row" *ngIf="pullTarget.zixi_encryption_key">
        <h5 class="col-12" translate>ENCRYPTION_KEY</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                {{ "SHOW" | translate }}
            </a>
            <span *ngIf="keyVisible">{{ pullTarget.zixi_encryption_key }}</span> /
            <a href="javascript:void(0)" (click)="copyString(pullTarget.zixi_encryption_key)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
</div>
<div class="details-form" *ngIf="srtTarget">
    <h4 class="mb-3" translate>CONFIGURE_SRT_CLIENT_WITH_FOLLOWING_PARAMS</h4>
    <div class="form-group row">
        <h5 class="col-12" translate>HOST</h5>
        <div class="col-12">
            <span
                >{{ srtTarget.hostPrefix }}.{{ applicationHost
                }}<button
                    class="btn btn-sm btn-xs btn-copy"
                    (click)="copyString(srtTarget.hostPrefix + '.' + applicationHost)"
                    type="button"
                    title="{{ 'COPY' | translate }}"
                >
                    <fa-icon icon="clipboard" size="md"></fa-icon>
                </button>
            </span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>PORT</h5>
        <div class="col-12">
            <span
                >{{ srtTarget.port }}
                <button class="btn btn-sm btn-xs btn-copy" (click)="copyString(srtTarget.port)" type="button" title="{{ 'COPY' | translate }}">
                    <fa-icon icon="clipboard" size="md"></fa-icon>
                </button>
            </span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>PASSWORD</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                {{ "SHOW" | translate }}
            </a>
            <span *ngIf="pwVisible">{{ srtTarget.password }}</span> /
            <a href="javascript:void(0)" (click)="copyString(srtTarget.password)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
</div>
