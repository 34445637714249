import { Component, Input, OnChanges, OnInit, ComponentRef } from "@angular/core";
import { ZixiPullTarget } from "../../../channels/channel";
import { BehaviorSubject } from "rxjs";
import { KeyMap } from "src/app/models/shared";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { ZxTargetComponent } from "src/app/components/shared/zx-target/zx-target.component";
import { TargetApiType } from "../../../channels/channel";

@Component({
    selector: "app-target-children",
    templateUrl: "./target-children.component.html"
})
export class TargetChildrenComponent implements OnInit, OnChanges {
    @Input() targets: ZixiPullTarget[];

    constructor(private translate: TranslateService) {}

    private targetsBS$ = new BehaviorSubject<ZixiPullTarget[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<ZixiPullTarget>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            component: ZxTargetComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ZxTargetComponent>,
                row: KeyMap<ZixiPullTarget>,
                searchTerm: string[]
            ) => {
                const ci = componentRef.instance;
                const props = {
                    model: { target: row, apiType: TargetApiType.Pull },
                    showOtherIcons: false,
                    showStatusIcon: false,
                    showStatusText: false,
                    showLink: true,
                    showTag: false,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    ci[key] = value;
                }
            },
            sortBy: row => row.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory<KeyMap<ZixiPullTarget>>({
                showOtherIcons: true
            }),
            sortBy: row => row.generalStatus
        },
        {
            header: this.translate.instant("TARGET"),
            columnDef: "target",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ZixiPullTarget>>(
                row => row.receiver_name,
                row => row.receiver_name,
                () => true
            ),
            sortBy: row => row.receiver_name
        }
    ];

    ngOnInit(): void {
        this.prepTableData();
    }

    async ngOnChanges() {
        this.prepTableData();
    }

    get targets$() {
        return this.targetsBS$.asObservable();
    }

    private prepTableData() {
        if (this.targets) {
            this.targetsBS$.next(this.targets);
        }
    }
}
